






































import clone from 'clone';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  @Prop([Number, Array]) readonly value!: number | number[];
  @Prop({ type: String, default: 'Event' }) readonly label!: string;
  events: API.Events.GET[] = [];
  loading = false;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.events = (await apiGETMulti('events', {}, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }

  filter(event: API.Events.GET, queryText: string): boolean {
    const q = queryText.toLowerCase();
    return event.name.toLowerCase().includes(q);
  }

  get multiple(): boolean {
    return Array.isArray(this.value);
  }

  removeMultiple(event: API.Events.GET): void {
    if (Array.isArray(this.value)) {
      const index = this.value.findIndex((id) => id === event.id);
      if (index >= 0) {
        const arr = clone(this.value);
        arr.splice(index, 1);
        this.$emit('input', arr);
      }
    }
  }

  input($event?: string | number[]): void {
    if (Array.isArray($event)) {
      this.$emit('input', $event);
    } else {
      this.$emit('input', $event ? parseFloat($event) : null);
    }
  }
}
