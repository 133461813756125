

























import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
  @PropSync('error', { type: [Boolean, String], required: true }) errProp!: boolean | string;

  get err(): boolean { return !!this.errProp; }
  set err(val: boolean) { this.errProp = val; }

  get formattedErr(): string | boolean {
    return typeof this.errProp === 'boolean'
      ? this.errProp
      : `${this.errProp.slice(0, 1).toUpperCase()}${this.errProp.slice(1)}.`;
  }

  keydown(evt: KeyboardEvent): void {
    if (evt.key === 'Escape' || evt.code === 'Escape') {
      this.$emit('cancel');
      this.dialogProp = false;
    }
  }
}
