















import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import { rootModule } from '@/store';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  players: API.Players.GET[] = [];
  loading = false;

  get player(): number | null { return rootModule.selectedPlayer; }
  set player(val: number | null) { rootModule.setSelectedPlayer(val); }

  get event(): number | null { return rootModule.selectedEvent; }

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    if (typeof this.event === 'number') {
      this.players = (await apiGETMulti(
        'players',
        { eventId: this.event, embed: 'user' },
        true,
      )).data;
    } else {
      this.players = [];
    }
    this.loading = false;
  }

  @Watch('event', { immediate: true })
  onEventChange(): void {
    this.loadAPIData();
  }
}
