








import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @PropSync('value', Boolean) valueProp!: boolean;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop({ type: String, required: true }) readonly icon!: string;
}
