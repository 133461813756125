

































import { Vue, Component, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import DataTable from '@/components/Admin/DataTable.vue';
import Modify from './Modify.vue';
import dayjs from 'dayjs';
import { rootModule } from '@/store';

@Component({
  components: {
    DataTable,
    Modify,
  },
})
export default class extends Vue {
  playerCards: API.PlayerCards.GET[] = []; // Only stores the current page of player cards!
  tableOptions = { page: 1, itemsPerPage: 10 };
  modifyPlayerCard: API.PlayerCards.GET | null = null; // Player Card to be modified/deleted.
  additionalParams: Partial<API.TypesMap['playerCards']['params']> = {};
  options = {
    apiName: 'playerCards',
    socketName: 'playerCardModified',
    idStr: 'id',
    embeds: [
      { name: 'player', socketName: 'playerModified', idStr: 'playerId' },
      { name: 'user', socketName: 'userModified', idStr: 'userId' },
      { name: 'event', socketName: 'eventModified', idStr: 'eventId' },
    ],
    headers: [
      { text: 'Player', value: 'user.name' },
      { text: 'Event', value: 'event.name' },
      { text: 'Message', value: 'msg' },
      { text: 'Created', value: 'creationTimestamp' },
      { text: 'Already Sent Once', value: 'sentOnce' },
      { text: 'Automatically Sent To Casters', value: 'autoSent' },
    ],
    modifyComponent: Modify,
  };
  dayjs = dayjs;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }
  get selectedPlayer(): number | null { return rootModule.selectedPlayer; }

  @Watch('selectedPlayer', { immediate: true })
  onSelectedPlayerChange(val: number | null): void {
    if (typeof val === 'number') Vue.set(this.additionalParams, 'playerId', val);
    else Vue.set(this.additionalParams, 'playerId', undefined);
  }
}
