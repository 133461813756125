






















import { Vue, Component, PropSync } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @PropSync('dialog', { type: Boolean, required: true }) dialogProp!: boolean;
}
