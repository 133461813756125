var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"id":"DataTable","headers":_vm.headers,"items":_vm.itemsSpecial || _vm.itemsProp,"loading":_vm.loading,"loading-text":"Loading...","no-data-text":_vm.noDataText,"options":_vm.tableOptionsProp,"disable-sort":true,"disable-filtering":true,"server-items-length":_vm.serverItemsLength,"footer-props":{
    'items-per-page-options': _vm.itemsPerPageOptions,
    'items-per-page-text': _vm.itemsPerPageText,
    'page-text': '{0}-{1}',
    'show-current-page': true,
    'show-first-last-page': true,
    'disable-pagination': _vm.loading,
  }},on:{"update:options":[function($event){_vm.tableOptionsProp=$event},_vm.optionsUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.options.modifyComponent)?_c(_vm.options.modifyComponent,{tag:"component",attrs:{"dialog":_vm.modifyDialog,"modify-item":_vm.modifyItemProp},on:{"update:dialog":function($event){_vm.modifyDialog=$event},"post":_vm.modifyPost}}):_vm._e(),(_vm.modifyItemProp)?_c('confirm-dialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirm":function($event){return _vm.delConfirmed()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._t("deleteConfirmationTitle")]},proxy:true},{key:"text",fn:function(){return [_vm._t("deleteConfirmationText")]},proxy:true}],null,true)}):_vm._e()]},proxy:true},(!_vm.options.hideActions)?{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_vm._t("additionalActions",null,null,{ item: item }),(!_vm.options.hideEdit)?_c('tooltip',{scopedSlots:_vm._u([{key:"elem",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.edit(item)}}},[_vm._t("editBtn",function(){return [_vm._v("mdi-pencil")]})],2)]},proxy:true},{key:"tooltip",fn:function(){return [_vm._t("editTooltip",function(){return [_vm._v("Edit")]})]},proxy:true}],null,true)}):_vm._e(),(!_vm.options.hideDelete)?_c('tooltip',{staticClass:"ml-2",scopedSlots:_vm._u([{key:"elem",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.del(item)}}},[_vm._v("mdi-delete")])]},proxy:true},{key:"tooltip",fn:function(){return [_vm._v("Delete")]},proxy:true}],null,true)}):_vm._e()],2)]}}:null,(!_vm.options.hideAdd)?{key:"footer.prepend",fn:function(){return [_c('tooltip',{staticClass:"ml-2",attrs:{"side":"right"},scopedSlots:_vm._u([{key:"elem",fn:function(){return [_c('v-icon',{on:{"click":function($event){_vm.modifyDialog = true}}},[_vm._t("addBtn")],2)]},proxy:true},{key:"tooltip",fn:function(){return [_vm._t("addTooltip")]},proxy:true}],null,true)})]},proxy:true}:null,_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }