































import { Vue, Component, Prop } from 'vue-property-decorator';
import Tooltip from '@/components/Admin/Tooltip.vue';

@Component({
  components: {
    Tooltip,
  },
})
export default class extends Vue {
  @Prop() readonly value!: unknown;
  @Prop(String) readonly type!: string;
  @Prop({ type: String, required: true }) readonly label!: string;
  @Prop({ type: String, required: true }) readonly icon!: string;
  @Prop(String) readonly appendIcon!: boolean;
  @Prop(String) readonly appendIconTooltip!: string;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly spellcheck!: boolean;

  input($event: string): void {
    if (this.type === 'number') {
      const parsed = parseFloat($event);
      this.$emit('input', !Number.isNaN(parsed) ? parsed : null);
    } else {
      this.$emit('input', $event);
    }
  }

  paste($event: ClipboardEvent): void {
    if ($event.clipboardData && this.type !== 'number') {
      $event.preventDefault();
      const filtered = $event.clipboardData?.getData('text').replace(/(?:\r\n|\r|\n)/g, '');
      this.$emit('input', filtered);
    }
  }
}
