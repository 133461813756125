var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"value":_vm.value,"label":_vm.label,"items":_vm.events,"item-text":"name","item-value":"id","filter":_vm.filter,"hide-details":"","filled":"","clearable":!_vm.multiple,"prepend-icon":"mdi-calendar","multiple":_vm.multiple,"chips":_vm.multiple,"loading":_vm.loading},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
var attrs = ref.attrs;
return [(!_vm.multiple)?[_vm._v(" "+_vm._s(item.name)+" ")]:_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeMultiple(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }