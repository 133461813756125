


























































































import { apiDELETE, apiGETMulti } from '@/api';
import { API } from '@/types';
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import Tooltip from '@/components/Admin/Tooltip.vue';
import ConfirmDialog from '@/components/Admin/ConfirmDialog.vue';

@Component({
  components: {
    Tooltip,
    ConfirmDialog,
  },
})
export default class extends Vue {
  @PropSync('items', { type: Array, required: true }) itemsProp!: { [k: string]: unknown }[];
  @Prop(Array) readonly itemsSpecial!: { [k: string]: unknown }[] | undefined;
  @PropSync('tableOptions', { type: Object, required: true }) tableOptionsProp!: DataOptions;
  @PropSync('modifyItem', Object) modifyItemProp!: { [k: string]: unknown } | null | undefined;
  @Prop(Number) readonly selectedEvent!: number | null | undefined;
  @Prop(Object) readonly additionalParams!: { [k: string]: unknown } | undefined;
  @Prop({ type: Object, required: true }) readonly options!: {
    apiName: keyof API.TypesMap,
    socketName: string,
    idStr: string,
    embeds?: {
      name: string,
      socketName: string,
      idStr: string,
    }[],
    headers: DataTableHeader[],
    hideEdit?: boolean,
    hideDelete?: boolean;
    hideActions?: boolean,
    hideAdd?: boolean,
    modifyComponent?: unknown, // type?
    reloadOnPropChanged?: string[],
  };
  @Prop(String) readonly noDataText!: string | undefined;
  @Prop({ type: Array, default: () => ([10, 20]) }) readonly itemsPerPageOptions!: number[];
  @Prop(String) readonly itemsPerPageText!: string | undefined;
  modifyDialog = false;
  deleteDialog = false;
  loading = false;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Vue.set(this, 'itemsProp', (await apiGETMulti<any>(this.options.apiName, {
      max: this.tableOptionsProp.itemsPerPage,
      offset: (this.tableOptionsProp.page - 1) * this.tableOptionsProp.itemsPerPage,
      embed: this.options.embeds
        ? this.options.embeds.map((e) => e.name) as ('user' | 'player' | 'card' | 'event')[]
        : undefined,
      eventId: this.selectedEvent !== null ? this.selectedEvent : undefined,
      ...this.additionalParams,
    })).data);
    await Vue.nextTick(); // Wait a tick for sync'd prop to fully update.
    if (this.itemsProp.length === 0 && this.tableOptionsProp.page > 1) {
      // Force page back 1 if no results found.
      Vue.set(this.tableOptionsProp, 'page', this.tableOptionsProp.page - 1);
    }
    this.loading = false;
  }

  async optionsUpdated(): Promise<void> {
    await Vue.nextTick(); // Wait a tick for sync'd prop to fully update.
    this.loadAPIData();
  }

  @Watch('selectedEvent')
  onSelectedEventChange(): void {
    this.loadAPIData(); // Force a reload if a specific event is selected.
    // TODO: Return to first page as well?
  }

  @Watch('additionalParams', { deep: true })
  onAdditionalParamsChange(): void {
    this.loadAPIData(); // Force a reload if a specific filter param is changed.
  }

  @Watch('modifyDialog')
  @Watch('deleteDialog')
  onDialogChange(val: boolean): void {
    if (!val) this.modifyItemProp = null;
  }

  edit(item: { [k: string]: unknown }): void {
    this.modifyItemProp = item;
    this.modifyDialog = true;
  }

  modifyPost(item: { [k: string]: unknown }): void {
    const index = this.itemsProp
      .findIndex((i) => i[this.options.idStr] === item[this.options.idStr]);
    const oldVal = this.itemsProp[index];
    const embedChange = this.options.embeds?.find((e) => oldVal?.[e.idStr] !== item[e.idStr]);
    const propChange = this.options.reloadOnPropChanged?.find((p) => oldVal?.[p] !== item[p]);
    if (index >= 0 && !embedChange && !propChange) {
      // If item exists on the page currently, just merge the difference in.
      Vue.set(this.itemsProp, index, { ...oldVal, ...item });
    } else {
      // Force a reload if an entry has been added, or if an embed needs updating.
      this.loadAPIData();
      // TODO: Return to first page instead if a new entry?
    }
  }

  del(item: { [k: string]: unknown }): void {
    this.modifyItemProp = item;
    this.deleteDialog = true;
  }

  // TODO: Handle errors!
  async delConfirmed(): Promise<void> {
    if (this.modifyItemProp?.[this.options.idStr]) {
      await apiDELETE(
        this.options.apiName,
        this.modifyItemProp?.[this.options.idStr] as string | number,
      );
      this.deleteDialog = false;
      this.loadAPIData(); // Force a reload if an entry has been deleted.
    }
  }

  onSocketMsg(
    name: string,
    newVal: { [k: string]: unknown } | null,
    oldVal?: { [k: string]: unknown } | null,
  ): void {
    const embedSockets = (this.options.embeds || []).filter((e) => e.socketName === name);
    if (name === this.options.socketName) {
      const index = this.itemsProp
        .findIndex((i) => i[this.options.idStr] === oldVal?.[this.options.idStr]);
      if (index >= 0 && newVal && oldVal) {
        const embedChange = this.options.embeds?.find((e) => oldVal[e.idStr] !== newVal[e.idStr]);
        const propChange = this.options.reloadOnPropChanged?.find((p) => oldVal?.[p] !== newVal[p]);
        if (!embedChange && !propChange) {
          // If item exists on the page currently, just merge the difference in.
          Vue.set(this.itemsProp, index, { ...this.itemsProp[index], ...newVal });
        } else {
          // Force a reload if something embedded needs updating or a listed prop has changed.
          this.loadAPIData();
        }
      } else if (!newVal || !oldVal) {
        // If item has been added/removed, do a full reload of the API page.
        this.loadAPIData();
      }
    } else if (embedSockets.length) { // Updates embeds if currently in a loaded entity.
      embedSockets.forEach((embed) => {
        this.itemsProp.forEach((item, i) => {
          // "newVal.id" is hardcoded, assumes all embeds use "id" as their primary key.
          if (newVal && oldVal && item[embed.idStr] === newVal.id) {
            Vue.set(
              this.itemsProp[i],
              embed.name,
              { ...this.itemsProp[i][embed.name] as { [k: string]: unknown }, ...newVal },
            );
          }
        });
      });
    }
  }

  created(): void {
    this.$socket.client.onAny(this.onSocketMsg);
  }

  beforeDestroy(): void {
    this.$socket.client.offAny(this.onSocketMsg);
  }

  get headers(): DataTableHeader[] {
    if (this.options.hideActions) return this.options.headers;
    return this.options.headers
      .concat([{ text: 'Actions', value: 'actions', align: 'end', width: '1%' }]);
  }

  // If the number of results matches the page length, we need to force the
  // "next page" button by having the length be 1 more than the max,
  // unless the "lastPage" variable says otherwise.
  get serverItemsLength(): number {
    const min = (this.tableOptionsProp.page - 1) * this.tableOptionsProp.itemsPerPage;
    const max = min + this.itemsProp.length;
    return this.itemsProp.length === this.tableOptionsProp.itemsPerPage ? max + 1 : max;
  }
}
