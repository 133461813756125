
























import { Vue, Component, Prop } from 'vue-property-decorator';
import { API } from '@/types';
import { apiGETMulti } from '@/api';

@Component
export default class extends Vue {
  @Prop(Number) readonly value!: number;
  players: API.Players.GET[] = [];
  loading = false;

  // TODO: Handle errors!
  async loadAPIData(): Promise<void> {
    this.loading = true;
    this.players = (await apiGETMulti('players', { embed: ['user', 'event'] }, true)).data;
    this.loading = false;
  }

  async created(): Promise<void> {
    await this.loadAPIData();
  }

  filter(player: API.Players.GET, queryText: string): boolean {
    const q = queryText.toLowerCase();
    return !!(player.user?.name.toLowerCase().includes(q)
    || (player.user?.twitch?.toLowerCase().includes(q))
    || (player.user?.twitter?.toLowerCase().includes(q))
    || (player.user?.discord?.toLowerCase().includes(q)));
  }
}
